import Head from 'next/head';
import { useRouter } from 'next/router';
import { FunctionComponent, useContext, useEffect } from 'react';

import { Home } from '@components/home';
import { defaultGettyTheme, defaultTheme } from '@constants/theme';
import { ConfigContext } from '@context/config';
import { OAuthContext } from '@context/oauth';
import { ThemeContext } from '@context/theme';
import { WhitelabelContext } from '@context/whitelabel';
import { SiteProps } from '@typings/site';
import { WhitelabelScope } from '@typings/whitelabel';

export const SiteHome: FunctionComponent<SiteProps> = (props) => {
  const { authed, brandguides, host, publicRuntimeConfig } = props;

  const router = useRouter();

  const { setPublicRuntimeConfig } = useContext(ConfigContext);
  const { setAuthed } = useContext(OAuthContext);
  const { setWhitelabelScope, whitelabel } = useContext(WhitelabelContext);

  const isGetty = host?.includes(publicRuntimeConfig.GETTY_BRANDGUIDE_HOST);
  const theme = isGetty ? defaultGettyTheme : defaultTheme;

  useEffect(() => {
    setPublicRuntimeConfig(publicRuntimeConfig);
    setWhitelabelScope(isGetty ? WhitelabelScope.Getty : WhitelabelScope.Brandfolder);

    // removes access_token and refresh_token query params when signing in
    if (router.query.access_token && router.query.refresh_token) {
      router.replace('/', undefined, { shallow: true });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    setAuthed(authed);
  }, [authed]); // eslint-disable-line react-hooks/exhaustive-deps

  const title = `Brandguide${authed ? ' | Home' : ' | Sign In'}`;

  return (
    <ThemeContext.Provider value={{ isLight: true, theme, setTheme: () => {} }}>
      <Head>
        <title>{title}</title>
        <meta name="description" content={`A styleguide for ${whitelabel.platformName}`} />
        <link rel="icon" href={theme.image.faviconHref} />
      </Head>
      <Home brandguides={brandguides} />
    </ThemeContext.Provider>
  );
};
