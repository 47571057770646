import { IncomingMessage } from 'node:http';

/**
 * Get if host is localhost.
 *
 * @param host string
 * @returns boolean
 */
export const getIsLocalHost = (host: string): boolean => {
  return host.includes('localhost') || host.includes('lvh.me') || host.includes('.local');
};

interface GetOriginReturn {
  host: string;
  origin: string;
  protocol: string;
}

/**
 * Get the host, origin, and protocol of a Node HTTP request.
 * Server-side only.
 *
 * @param req IncomingMessage
 * @returns GetOriginReturn
 */
export const getOrigin = (req: IncomingMessage): GetOriginReturn => {
  const host = req.headers.host as string;
  const protocol = getIsLocalHost(host) ? 'http:' : 'https:';
  return {
    host,
    origin: `${protocol}//${host}`,
    protocol
  };
};
