import {
  ButtonLink,
  ButtonLooks,
  FontAlignments,
  FontSizes,
  StandardHeading,
  StandardLink,
  StandardText
} from '@brandfolder/react';
import classNames from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import { FunctionComponent, HTMLAttributes, useContext, useEffect } from 'react';
import styled from 'styled-components';

import { WhitelabelContext } from '@context/whitelabel';
import { WhitelabelScope } from '@typings/whitelabel';
import { getFirstQueryParam } from '@utilities/query';
import { getIsLocalHost } from '@utilities/server';

interface HomeSignInProps extends HTMLAttributes<HTMLDivElement> {}

const UnstyledHomeSignIn: FunctionComponent<HomeSignInProps> = (props) => {
  const router = useRouter();

  const cname = getFirstQueryParam(router.query.cname);
  const org_key = getFirstQueryParam(router.query.org_key);
  const redirect = getFirstQueryParam(router.query.redirect);

  const autoSignIn = !!cname || !!redirect;

  const { whitelabel, whitelabelScope } = useContext(WhitelabelContext);

  const redirectToSignIn = async () => {
    const { BRANDGUIDE_URL } = whitelabel;

    /**
     * This check is needed for GETTY_BRANDGUIDE_HOST, which is set without the https://
     */
    const host =
      BRANDGUIDE_URL.startsWith('https://') || getIsLocalHost(BRANDGUIDE_URL)
        ? BRANDGUIDE_URL
        : `https://${BRANDGUIDE_URL}`;

    const url = new URL(`${host}/sign-in`);

    if (cname) url.searchParams.set('cname', cname);
    if (redirect) url.searchParams.set('redirect', redirect);
    if (org_key) url.searchParams.set('org_key', org_key);

    await router.push(url.toString());
  };

  useEffect(() => {
    if (autoSignIn && whitelabel.BRANDGUIDE_URL) {
      redirectToSignIn();
    }
  }, [autoSignIn, whitelabel.BRANDGUIDE_URL]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      {...props}
      className={classNames({
        'home__sign-in': true,
        [`${props?.className}`]: !!props?.className
      })}
    >
      <section className="section">
        <Image
          alt={whitelabel.brandName}
          className="image"
          height={58}
          src={whitelabel.logoCdnUrl}
          width={250}
        />
        <div className="content">
          <StandardHeading
            align={FontAlignments.Center}
            className="heading"
            size={FontSizes.XLarge}
          >
            Define your brand narrative
          </StandardHeading>
          <div className="intro-wrapper">
            <StandardText align={FontAlignments.Center} className="p-one" size={FontSizes.Medium}>
              {' '}
              You&apos;ve trusted {whitelabel.platformName} with your assets, now let us help you
              tell your brand story in only the way you can tell it.
            </StandardText>
            <StandardText align={FontAlignments.Center} className="p-two" size={FontSizes.Medium}>
              {' '}
              With Brandguide, you can create a simple, straightforward, web-based styleguide that
              looks and feels like your brand -- allowing you to tell your brand story your way.{' '}
              <StandardLink
                className="learn-more-link"
                href={
                  whitelabelScope === WhitelabelScope.Brandfolder
                    ? 'https://brandfolder.com/product/brandguide/'
                    : `${whitelabel.helpDocsBase}/en/article/brandguide-tutorial`
                }
              >
                Learn more.
              </StandardLink>
            </StandardText>
          </div>
        </div>
        <ButtonLink
          className="sign-in-button"
          disabled={autoSignIn}
          href="/sign-in"
          look={ButtonLooks.Primary}
        >
          {autoSignIn ? 'Signing in....' : 'Sign in to access your Brandguides'}
        </ButtonLink>
      </section>
      <div className="background">
        {whitelabelScope === WhitelabelScope.Brandfolder && (
          <Image
            alt=""
            className="brandfolder-b"
            height={1000}
            src="https://cdn.bfldr.com/27C9EC93/at/q8y6v3-7yddcg-brgt1m/brandfolder-icon.svg?auto=webp&format=svg"
            width={1000}
          />
        )}
      </div>
    </div>
  );
};

const StyledHomeSignIn = styled(UnstyledHomeSignIn)`
  align-items: center;
  display: grid;
  justify-content: center;
  min-height: 100vh;
  overflow: hidden;
  padding: 60px 30px;

  .section {
    position: relative;
    text-align: center;
    z-index: 2;
  }

  .content {
    max-width: 455px;
  }

  .heading {
    line-height: 32px;
    margin: 60px 0 20px;
  }

  .image {
    height: auto;
    margin: 0 auto;
    max-width: 250px;
    width: 100%;
  }

  .intro-wrapper {
    margin-bottom: 50px;

    @media (min-width: 768px) {
      margin-bottom: 80px;
    }
  }

  .p-one {
    margin-bottom: 30px;
  }

  .background {
    height: 100%;
    left: 0;
    position: absolute;
    overflow: hidden;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 1;
  }

  .brandfolder-b {
    filter: brightness(0) invert(1);
    height: auto;
    left: -50px;
    max-width: 95%;
    position: absolute;
    top: 150px;
    transform: rotate(-15deg);
    width: 100%;

    @media (min-width: 768px) {
      left: -10%;
      max-width: 90%;
      top: 15%;
    }

    @media (min-width: 1024px) {
      max-width: 80%;
    }

    @media (min-width: 1280px) {
      max-width: 70%;
    }

    @media (min-width: 1600px) {
      max-width: 60%;
    }

    @media (min-width: 1920px) {
      max-width: 50%;
    }
  }
`;

export const HomeSignIn: FunctionComponent<HomeSignInProps> = (props) => {
  return <StyledHomeSignIn {...props} />;
};
