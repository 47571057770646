import type { GetServerSideProps, NextPage } from 'next';

import { SiteBrandguide } from '@components/site/brandguide';
import { SiteHome } from '@components/site/home';
import { SiteProps } from '@typings/site';
import { getIsBrandguideCname } from '@utilities/brandguide';
import { getBrandguideProps, getHomepageProps } from '@utilities/site';

/**
 * If the Brandguide is CNAME-ed, this page renders a Brandguide and its homepage,
 * like https://brandguide.smartsheet.com
 *
 * Otherwise, we render the default site home sign in prompt or Brandguides list
 * at https://brandguides.brandfolder.com.
 */
const Index: NextPage<SiteProps> = (props) => {
  return props.isCname ? <SiteBrandguide {...props} /> : <SiteHome {...props} />;
};

export const getServerSideProps: GetServerSideProps<SiteProps> = async (context) => {
  const host = context.req.headers.host || '';

  const isCname = getIsBrandguideCname(
    [process.env.GETTY_BRANDGUIDE_HOST, process.env.NEXTAUTH_URL as string],
    host
  );

  if (isCname) {
    const props = await getBrandguideProps(context, isCname);
    return props;
  } else {
    const props = await getHomepageProps(context);
    return props;
  }
};

export default Index;
