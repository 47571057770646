import classNames from 'classnames';
import dynamic from 'next/dynamic';
import { FunctionComponent, HTMLAttributes, useContext } from 'react';
import styled from 'styled-components';

import { Main } from '@components/main';
import { Page } from '@components/page';
import { OAuthContext } from '@context/oauth';
import { ApiData } from '@typings/api';
import { Brandguide } from '@typings/brandguide';

import { HomeSignIn } from './home-sign-in';

/**
 * These components are only needed when editing client-side, so they should not be
 * contributing to the JS page load, and instead can be loaded via code splitting.
 * Be sure to check `yarn build` after modifying.
 */
const Header = dynamic(() => import('../header/header'), { ssr: false });
const HomeList = dynamic(() => import('./home-list'), { ssr: false });
const HomeEmpty = dynamic(() => import('./home-empty'), { ssr: false });

interface HomeProps extends HTMLAttributes<HTMLDivElement> {
  brandguides: ApiData<Brandguide> | null;
}

const UnstyledHome: FunctionComponent<HomeProps> = (props) => {
  const { brandguides, ...otherProps } = props;

  const { authed } = useContext(OAuthContext);

  return (
    <Page
      className={classNames({
        page__home: true,
        [`${otherProps?.className}`]: !!otherProps?.className
      })}
      home
    >
      <Header home />
      <Main>
        {!authed && <HomeSignIn />}
        {authed && brandguides && brandguides?.data.length > 0 && (
          <HomeList brandguides={brandguides} />
        )}
        {authed && brandguides && brandguides?.data.length === 0 && <HomeEmpty />}
      </Main>
    </Page>
  );
};

const StyledHome = styled(UnstyledHome)`
  .main--loading {
    align-items: center;
    display: flex;
    justify-content: center;
  }
`;

export const Home: FunctionComponent<HomeProps> = (props) => {
  return <StyledHome {...props} />;
};
